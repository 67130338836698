<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>messages-people-man-bubble-circle-1</title>
    <path
      d="M16.5,0a7.676,7.676,0,0,0-6.162,3.007,1,1,0,0,0,1.6,1.195A5.673,5.673,0,0,1,16.5,2,5.274,5.274,0,0,1,22,7a5.006,5.006,0,0,1-3.358,4.605,1.012,1.012,0,0,0-.32.2c-.2.184-2.068,1.773-2.324,1.991,0-.366,0-.681,0-.858a1,1,0,0,0-.861-1,5.781,5.781,0,0,1-.787-.166A1,1,0,1,0,13.8,13.7l.2.054c0,.511,0,2.25,0,2.25a1,1,0,0,0,1.707.707c.268-.267,3.543-3.054,3.828-3.305A6.973,6.973,0,0,0,24,7,7.271,7.271,0,0,0,16.5,0Z"
    />
    <circle cx="13.116" cy="6.5" r="1" />
    <circle cx="16.366" cy="6.5" r="1" />
    <circle cx="19.616" cy="6.5" r="1" />
    <path
      d="M7,17.125a4.75,4.75,0,1,0-4.75-4.75A4.756,4.756,0,0,0,7,17.125ZM4.549,11.154A7.043,7.043,0,0,0,8.46,12.375a6.6,6.6,0,0,0,1.277-.125c0,.042.013.082.013.125a2.75,2.75,0,0,1-5.5,0A2.713,2.713,0,0,1,4.549,11.154Z"
    />
    <path d="M7.206,18A7.544,7.544,0,0,0,.021,23.357.5.5,0,0,0,.5,24H13.912a.5.5,0,0,0,.48-.643A7.544,7.544,0,0,0,7.206,18Z" />
  </svg>
</template>
